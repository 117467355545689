import React from 'react'
import classnames from 'classnames'
import styles from './TextBody.module.sass'

const TextBody = ({ center, small, className, children }) => {
  const cl = classnames(
    {
      [styles.body]: true,
      [styles.center]: center,
      [styles.small]: small,
    },
    className
  )
  return <div className={cl}>{children}</div>
}

export default TextBody
