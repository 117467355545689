import React from 'react'
import classnames from 'classnames'
import { Trans } from '@lingui/macro'
import Section from './Section.js'
import Container from './Container.js'
import TextBody from './TextBody.js'
import common from './commonStyles.module.sass'
import imgDevicePop from '../graphics/device-pop.svg'
import imgPplAnalytics from '../graphics/screens-analytics.jpg'

const SectionPeopleAnalytics = () => {
  return (
    <Section addPadding alternative id="people-analytics">
      <Container>
        <div className={classnames(common.split, common.alternative)}>
          <TextBody>
            <h2>
              <Trans>People Analytics</Trans>
            </h2>
            <h3>
              <Trans>Make Informed Decisions</Trans>
            </h3>
            <Trans>
              <p>
                Collect continuous feedback from your organization by measuring
                psycho-social work environment, engagement and eNPS in real time
                with a minimum set of questions. Monitor and filter results to
                get actionable insights on where your organization is right now
                and where it is heading.
              </p>
            </Trans>
            <h3>
              <Trans>Turn Insight to Action</Trans>
            </h3>
            <Trans>
              <p>
                To avoid survey fatigue, follow our process to quickly turn
                employee feedback into action and support the organisation in
                the process. Use Remente’s toolbox of resources and goal setting
                to shorten the time from survey to analysis and action.
              </p>
            </Trans>
          </TextBody>
          <div>
            <img src={imgDevicePop} alt="" />
          </div>
        </div>
        <figure className={common.figure}>
          <img src={imgPplAnalytics} alt="" />
        </figure>
      </Container>
    </Section>
  )
}

export default SectionPeopleAnalytics
