import React from 'react'
import { Trans } from '@lingui/macro'
import TextBody from './TextBody.js'
import CtaButton from './CtaButton.js'
import styles from './FeatureTile.module.sass'

const FeatureTile = ({ title, subTitle, body, img, ...props }) => {
  return (
    <div className={styles.feature}>
      <img src={img} alt="" />
      <h3>
        {title}
        <small>{subTitle}</small>
      </h3>
      <TextBody small className={styles.body}>
        <p>{body}</p>
      </TextBody>
      <CtaButton {...props}>
        <Trans>Learn More</Trans>
      </CtaButton>
    </div>
  )
}

export default FeatureTile
