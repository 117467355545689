import React from 'react'
import TextBody from './TextBody.js'
import imgStar from '../graphics/star.svg'
import styles from './Review.module.sass'

const Star = () => <img src={imgStar} alt="" />

const Review = ({ rating = 5, children }) => {
  return (
    <div className={styles.review}>
      <div className={styles.stars}>{renderStars(rating)}</div>
      <TextBody small>{children}</TextBody>
    </div>
  )
}

function renderStars(rating) {
  const res = []
  for (let i = 0; i < rating; i++) {
    res.push(<Star key={i} />)
  }
  return res
}

export default Review
