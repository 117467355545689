import React from 'react'
import { Trans } from '@lingui/macro'
import Section from './Section.js'
import Container from './Container.js'
import TextBody from './TextBody.js'
import FeatureTile from './FeatureTile.js'
import imgWorkLife from '../graphics/work-life-balance.svg'
import imgPplAnalytics from '../graphics/people-analytics.svg'
import imgGoalsHabits from '../graphics/goals-habits.svg'
import styles from './SectionKeysSuccess.module.sass'

const SectionKeysSuccess = () => {
  return (
    <Section addPadding className={styles.section} id="keys-to-success">
      <Container>
        <TextBody center>
          <h2>
            <Trans>The keys to a successful business</Trans>
          </h2>
          <Trans>
            <p>
              Remente provides the organization with a digital platform to
              improve company performance, engagement and results, while
              employees get the tools and insights to influence their
              productivity and wellbeing, on and off work.
            </p>
          </Trans>
        </TextBody>
        <div className={styles.features}>
          <FeatureTile
            title={<Trans>Work-life Balance</Trans>}
            subTitle={<Trans>A Holistic Approach</Trans>}
            href="#work-life-balance"
            Component="a"
            body={
              <Trans>
                Coach every employee with action based courses and goal plans in
                healthy living, personal & professional leadership, and add your
                own custom content.
              </Trans>
            }
            img={imgWorkLife}
          />
          <FeatureTile
            title={<Trans>People Analytics</Trans>}
            subTitle={<Trans>Make Informed Decisions</Trans>}
            href="#people-analytics"
            Component="a"
            body={
              <Trans>
                Know the status of your organization by monitoring eNPS, health
                & psycho-social work environment in real-time.
              </Trans>
            }
            img={imgPplAnalytics}
          />
          <FeatureTile
            title={<Trans>Drive Performance</Trans>}
            subTitle={<Trans>Personal Leadership</Trans>}
            href="#drive-performance"
            Component="a"
            body={
              <Trans>
                Set individual, team-based, and organizational goals. Make goals
                actionable by connecting them to daily routines and behaviors.
              </Trans>
            }
            img={imgGoalsHabits}
          />
        </div>
      </Container>
    </Section>
  )
}

export default SectionKeysSuccess
