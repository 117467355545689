import React from 'react'
import Section from './Section.js'
import Container from './Container.js'
import styles from './BannerList.module.sass'

const BannerList = ({ banners = [] }) => {
  return (
    <Section className={styles.section}>
      <Container>
        <ul className={styles.banners}>{banners.map(Banner)}</ul>
      </Container>
    </Section>
  )
}

const Banner = ({ name, image }, i) => {
  return (
    <li className={styles.banner} key={i}>
      <img src={image} alt={name} />
    </li>
  )
}

export default BannerList
