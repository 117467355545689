import React from 'react'
import { Trans } from '@lingui/macro'
import Section from './Section.js'
import Container from './Container.js'
import TextBody from './TextBody.js'
import Review from './Review.js'
import styles from './SectionReviews.module.sass'

const SectionReviews = () => {
  return (
    <Section addPadding alternative>
      <Container>
        <TextBody center>
          <h2>
            <Trans>Enterprise Software That Users Actually Love</Trans>
          </h2>
          <Trans>
            <p>
              Yet another B2B SaaS solution that looks good in theory but no one
              wants to use, you might think... Well, before we launched our B2B
              product, we decided to test it with people from all over the
              world, to create a product that helps them in their life, and that
              they actually want to use. Why shouldn’t business apps be as
              beautiful, fun and engaging as consumer apps? With Remente, you
              get a consumer app experience with powerful business features.
              Here’s what some of our users say:
            </p>
          </Trans>
        </TextBody>
        <div className={styles.reviews}>
          <Review>
            <p>
              I’m loving this app! It has so many helpful tools to help me with
              my daily and long term goals.
            </p>
          </Review>
          <Review>
            <p>
              Very useful for goal setting, breaking down tasks and assessing
              life balance, mood, and reading strategies!
            </p>
          </Review>
          <Review>
            <p>
              Amazing! I’m am so grateful to have found this app. This morning I
              was severely depressed and stressed and this app helped me turn
              around...
            </p>
          </Review>
        </div>
      </Container>
    </Section>
  )
}

export default SectionReviews
