import React from 'react'
import BannerList from './BannerList.js'
import actea from '../graphics/clients/actea.png'
import gpbm from '../graphics/clients/gpbm.png'
import ifab from '../graphics/clients/ifab.png'
import fyndiq from '../graphics/clients/fyndiq.png'
import stena from '../graphics/clients/stena.png'
import eniro from '../graphics/clients/eniro.png'
import client1928 from '../graphics/clients/1928.png'

const clients = [
  { name: 'gpbm', image: gpbm },
  { name: 'actea', image: actea },
  { name: 'fyndiq', image: fyndiq },
  { name: 'stena', image: stena },
  { name: 'ifab', image: ifab },
  { name: 'eniro', image: eniro },
  { name: '1928', image: client1928 },
]

const SectionClientBanners = () => {
  return <BannerList banners={clients} />
}

export default SectionClientBanners
