import React from 'react'
import classnames from 'classnames'
import { Trans } from '@lingui/macro'
import Section from './Section.js'
import Container from './Container.js'
import BookDemoCtaButton from './BookDemoCtaButton.js'
import styles from './SectionHero.module.sass'
import imgHero from '../graphics/intro-hero.png'

const SectionHero = () => {
  return (
    <div className={styles.outerSection}>
      <Section addPadding className={styles.section}>
        <div className={styles.squig} />
        <div className={classnames(styles.squig, styles.right)} />
        <Container>
          <div className={styles.content}>
            <div className={styles.body}>
              <h1>
                <Trans>
                  <strong>Improve Wellbeing&nbsp;</strong>& Performance
                </Trans>
              </h1>
              <p>
                <Trans>
                  Let the leading platform for personal development transform
                  your business.
                </Trans>
              </p>
              <BookDemoCtaButton />
            </div>
            <div className={styles.screens}>
              <img src={imgHero} alt="" />
            </div>
          </div>
        </Container>
      </Section>
    </div>
  )
}

export default SectionHero
