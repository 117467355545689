import React from 'react'
import { Trans } from '@lingui/macro'
import Section from './Section.js'
import Container from './Container.js'
import TextBody from './TextBody.js'
import common from './commonStyles.module.sass'
import imgDrive from '../graphics/drive.svg'
import imgResources from '../graphics/screens-resources.jpg'

const SectionGoalsHabits = () => {
  return (
    <Section addPadding id="drive-performance">
      <Container>
        <div className={common.split}>
          <TextBody>
            <h2>
              <Trans>Goals & Habits</Trans>
            </h2>
            <h3>
              <Trans>Personal Leadership</Trans>
            </h3>
            <Trans>
              <p>
                Put your staff in the driver’s seat of their lives by letting
                Remente’s personal leadership system connect long term goals
                with daily tasks and habits. Drive performance and healthy
                behavior with corporate and personal goal setting that easily
                can be applied to daily life. Let the organization cascade,
                share and delegate goals. By having a clear direction and
                following healthy habits, performance will improve and stress go
                down.
              </p>
            </Trans>
            <h3>
              <Trans>Based On The Brain And Our Behavior</Trans>
            </h3>
            <Trans>
              <p>
                The goal and habit building process Remente uses is built on how
                the brain works and performs. The team behind Remente has +30
                years experience from psychology, mental training and how to
                train the brain. By working with the brain instead of against
                it, you can improve both performance and wellbeing, and we will
                guide you to how.
              </p>
            </Trans>
          </TextBody>
          <div>
            <img src={imgDrive} alt="" />
          </div>
        </div>
        <figure className={common.figure}>
          <img src={imgResources} alt="" />
        </figure>
      </Container>
    </Section>
  )
}

export default SectionGoalsHabits
