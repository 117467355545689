import React from 'react'
import { Trans } from '@lingui/macro'
import Section from './Section.js'
import Container from './Container.js'
import TextBody from './TextBody.js'
import common from './commonStyles.module.sass'
import imgBalance from '../graphics/balance.svg'
import imgPlan from '../graphics/screens-plan.jpg'

const SectionWorkLifeBalance = () => {
  return (
    <Section addPadding id="work-life-balance">
      <Container>
        <div className={common.split}>
          <TextBody>
            <h2>
              <Trans>Work-life Balance</Trans>
            </h2>
            <h3>
              <Trans>A Holistic Approach</Trans>
            </h3>
            <Trans>
              <p>
                Tested on over 1 million users globally, Remente’s digital
                platform combines science with a holistic approach to helping
                employees manage their lives, at home and at work. Based on
                organizational insights, Remente provides every employee with
                courses, suggestions, goal plans and tools for wellbeing and
                performance from our library of 120+ resources.
              </p>
            </Trans>
            <h3>
              <Trans>From Intent to Impact</Trans>
            </h3>
            <Trans>
              <p>
                One of the major challenges of learning & development efforts is
                its focus on insight rather than implementation and impact. Our
                action-based learning system provides insights that trigger
                intent which converts to impact, as our system uniquely connects
                learning with goals, day plans and notifications to help the
                user turn insights to actions. You can even add your own custom
                content. Beautifully packaged and top rated by users around the
                world, available 24/7 through a smartphone, computer or tablet.
              </p>
            </Trans>
          </TextBody>
          <div>
            <img src={imgBalance} alt="" />
          </div>
        </div>
        <figure className={common.figure}>
          <img src={imgPlan} alt="" />
        </figure>
      </Container>
    </Section>
  )
}

export default SectionWorkLifeBalance
